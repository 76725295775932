import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets } from "ticketino-api-client";
import axios from "axios";

import Header from './components/Header'
import Footer from './components/Footer'
import Loading from "./components/Loading";

const Home = () => {
    const [orderId, setOrderId] = useState();

    const [eventId, setEventId] = useState([]);
    const [posId, setPosId] = useState();
    const [event, setEvent] = useState({});

    const [ticketTypes, setTicketTypes] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [moreInfo, setMoreInfo] = useState({});

    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    const [promotioncode, setPromotioncode] = useState("");
    const [promotioncodeAdded, setPromotioncodeAdded] = useState("");
    const [promotionError, setPromotionError] = useState("");

    const [unlockedTicketTypes, setUnlockedTicketTypes] = useState([]);
    const [isTicketTypesUnlocked, setIsTicketTypesUnlocked] = useState(false);
    const [isTicketTypesLoaded, setIsTicketTypesLoaded] = useState(false);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language, urlPromocode } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();

        // clear the addedPromocodes in the session
        let array = [];
        sessionStorage.setItem("AddedPromotions", JSON.stringify(array));
    }, []); //only on first page load

    useEffect(() => {
        requestResources();

        // so it wont get called on first load
        if (loaded) {
            startOrder(posId, eventId);
        }
    }, [language]); //everytime language is changed

    useEffect(() => {
        if (orderId) {
            loadEvent(eventId, orderId)
        }
    }, [orderId]); //gets called when an order is started

    useEffect(() => {
        if (urlPromocode) {
            setPromotioncode(urlPromocode);
        }
    }, [urlPromocode]);

    useEffect(() => {
        if (isTicketTypesLoaded === true && orderId && promotioncode && urlPromocode) {
            addPromotionToOrder();
        }
    }, [isTicketTypesLoaded, promotioncode]); 


    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setEventId(res.data.eventId);

            startOrder(res.data.posId, res.data.eventId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);
                setOrderId(res.data.id);
                setLoaded(true);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            // sorting it according to the language
            const eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            // TicketType
            const ticketTypes = await getTicketTypesByEventId(eventId);

            const updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                })
            )

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setIsTicketTypesLoaded(true);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    const applyPromotionCodeToOrder = async (orderId, code) => {
        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources?.PromotionError);
        }
    }

    const deletePromotionCodeFromOrder = async (orderId, code) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources?.PromotionError);
        }
    }

    const getTicketTypeAvailability = async (ticketTypeId, orderId, promotionCode) => {
        try {
            const response = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}&promotionCode=${promotionCode}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources?.PromotionError);
        }
    }

    const addPromotionToOrder = async () => {
        setPromotionError("");
        setLoading(true);

        const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(orderId, promotioncode);

        if (!updatedUnlockedTicketTypes || !updatedUnlockedTicketTypes.unlockedTicketTypes ||
            updatedUnlockedTicketTypes.unlockedTicketTypes.length === 0) {
            setLoading(false);
            return;
        }

        let updatedTicketTypes = [];

        updatedTicketTypes = await Promise.all(ticketTypes.map(async (ticketType) => {
            if (updatedUnlockedTicketTypes?.unlockedTicketTypes?.includes(ticketType.id)) {
                const unlockedTicketTypeAvailability = await getTicketTypeAvailability(ticketType.id, orderId, promotioncode);
                ticketType.availability = unlockedTicketTypeAvailability;
            }
            return ticketType;
        }))

        sessionStorage.setItem("addedPromotion", promotioncode);
        setPromotioncodeAdded(promotioncode);
        
        setTicketTypes(updatedTicketTypes)
        setUnlockedTicketTypes(updatedUnlockedTicketTypes?.unlockedTicketTypes ?? []);
        setIsTicketTypesUnlocked(true);
        setLoading(false);
    }

    const removePromotionFromOrder = async () => {
        setPromotionError("");
        setLoading(true);

        await deletePromotionCodeFromOrder(orderId, promotioncode);
        await loadEvent(eventId, orderId);

        setUnlockedTicketTypes([]);
        setIsTicketTypesUnlocked(false);
        setLoading(false);
    }

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        addTicketTypes = addTicketTypes.map((att) => {
            if (unlockedTicketTypes.includes(att.ticketTypeId)) {
                att.promotionCode = promotioncodeAdded;
            }

            return att;
        })

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                // gtm - addToCart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'addToCart'
                });

                navigate(`/${language}/address`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addTicketToBasket();
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        const obj = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        const existingTicketType = selectedTicketTypes.find(
            (t) => t.ticketTypeId === ticketTypeId
        );

        if (existingTicketType) {
            existingTicketType.quantity = e.target.value;
        } else {
            selectedTicketTypes.push(obj);
        }

        // calcualate quantity and validate
        let quantity = 0;

        selectedTicketTypes.map(s => {
            quantity += s.quantity * 1;
        });

        if (quantity > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const promotionCodeDiv = () => {
        return (
            <div className="row">
                <div className="col-md-8 col-12 mb-3">
                    <input className="form-control" value={promotioncode} onChange={(e) => setPromotioncode(e.target.value)} />
                </div>
                <div className="col-md-4 col-12 mb-3 row text-end pe-0">
                    <div className="col"><button className="btn button col-12" onClick={addPromotionToOrder}>{resources?.PromotionRedeem}</button></div>
                    <div className="col pe-0"><button className="btn button col-12" onClick={removePromotionFromOrder}>{resources?.PromotionRemove}</button></div>
                </div>
            </div>
        )
    }

    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        if (isTicketTypesUnlocked) {
            ticketTypesSorted = ticketTypesSorted.filter((tts) => unlockedTicketTypes.includes(tts.id));
        }

        return (
            ticketTypes &&
            ticketTypes.length > 0 &&
            ticketTypesSorted.map((tt, index) => (
                <div key={index}>
                    {
                        (tt.availability?.status === 0) &&
                        (
                            <div className="row pt-2 pb-2">
                                <div className="col-md-8 col-6">
                                    <label className="fs-6">
                                        {tt.info?.name}
                                    </label>
                                    <div className="description">
                                        <span onClick={() => setMoreInfo({ [tt.id]: !moreInfo[tt.id] })}>{resources.OtherInfos} <i className={"bi bi-chevron-" + (moreInfo[tt.id] ? "up" : "down")}></i></span><br></br>
                                        {moreInfo[tt.id] && tt.info?.description}
                                    </div>
                                </div>
                                <div className="col-md-2 col-3 text-center">
                                    <label>
                                        {tt.currency} {tt.price}.-
                                    </label>
                                </div>
                                <div className="col-md-2 col-3">
                                    <select
                                        className="form-select"
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                    >
                                        {Array.from(
                                            Array(
                                                tt.availability?.availableTickets + 1
                                            ),
                                            (e, i) => {
                                                return (
                                                    <option key={i} value={i}>
                                                        {i}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}
                </div>
            ))
        );
    };

    return (
        <div>
            <Header />
            <div className="container mt-4 page-container">
                <div className="mb-4">
                    <p className="fs-5 fw-bold mb-2">{resources?.Location1}</p>
                    <hr></hr>
                    <p className="m-0 fs-6">{resources?.Location2}</p>
                    <p className="m-0 fs-6">{resources?.Location3}</p>
                </div>
                <div>
                    <p className="fs-5 fw-bold mb-2">{resources?.PromotionCode}</p>
                    <hr></hr>
                    <p className="fs-6">{resources?.PromotionDescription}</p>
                    {promotionCodeDiv()}
                    {promotionError !== "" && 
                        <p className="text-danger fw-bold">{promotionError}</p>
                    }
                    <p className="fs-6 mb-4">{resources?.PromotionDescription2}</p>
                </div>
                <div>
                    <p className="fs-5 fw-bold mb-2">Tickets</p>
                    <hr></hr>
                    {!loading ? mapTicketTypes() : <Loading bgColor="#d2edf4" color="#db1323" />}
                </div>
                <div className="mt-4 mb-4">
                    <button className="btn button" disabled={disabled} onClick={() => onSubmit()}>
                        {resources?.Next}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home